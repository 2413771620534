import { useEffect, useState } from "react";
import { autoCompleteProps } from "./MemberAutoComplete";
import { OptionTypeBase } from "react-select/src/types"
import { getCribLookupsById, getCribLookupsTypeahead } from "../../../adapters";
import { RequiredIndicator } from "../../../features/registeredListing/RegisteredListingForm";
import AsyncSelect from "react-select/async";

const LocationAutoComplete = ({handleChange, handleBlur, errors, values, touched, setFieldValue, n, className}: autoCompleteProps) => {
    const [input, setInput] = useState<OptionTypeBase | null>(null);
    const [filteredOptions, setFilteredOptions] = useState<OptionTypeBase[]>([]);
    const disabled = filteredOptions.length === 0;
    const inputClass = touched[n.id] && errors[n.id] ? "input-registered-invalid" : (n.required ? "input-registered-required" : "input-registered") + (disabled ? " text-gray-50" : "");
    let groupId: number;

    const onChange = (e: any) => {
        setInput(e);
        if (e) {
            setFieldValue(n.id, e.value);
        }
    }

    switch (n.id) {
        case 'city':
            groupId = 8;
            break;
        default:
        case 'countyOrParish':
            groupId = 3;
            break;
    }

    useEffect(() => {
        if (values[n.id].length && filteredOptions.length) {
            let inputValue = filteredOptions.find(element => {
                // check the value from formik against the value and label, since azure autocomplete doesn't know the shortcode
                return (element.value === values[n.id] || element.label === values[n.id]);
            });

            if (inputValue) {
                setInput(inputValue);
                setFieldValue(n.id, inputValue.value);
            }
        }
    }, [values, filteredOptions, n.id, setFieldValue]);

    useEffect(() => {
        setFilteredOptions([]);
        getCribLookupsById(groupId)
            .then(function(response) {
                const options = response.data.map((i: {shortValue: string, longValue: string}) => ({
                    value: i.shortValue,
                    label: i.longValue,
                }));
                setFilteredOptions(options);
            })
    }, [groupId]);

    const loadOptions = (inputText: string, callback: (options: OptionTypeBase[]) => void): void => {
        if (inputText.length >= 3) {
            getCribLookupsTypeahead(groupId, inputText).then((response) => {
                callback(
                    response.data.map((result: any) => {
                        return {
                            label: result.longValue,
                            value: result.shortValue,
                        };
                    })
                );
            });
        }
    };

    return (
        <div key={`${n.type}${n.id}`}
             className={className ?? "crmls-field-wrap"}>
            <label htmlFor={n.id}>
                {n.label} {n.required && RequiredIndicator}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <AsyncSelect 
                        unstyled={true} 
                        id={n.id} 
                        name={n.id} 
                        isClearable={true} 
                        onBlur={handleBlur} 
                        onChange={onChange} 
                        placeholder={`Enter ${n.label}`} 
                        value={input} 
                        classNamePrefix="custom-input" 
                        className={inputClass} 
                        loadOptions={loadOptions} 
                    />
                </div>
            </div>
            {touched[n.id] && errors[n.id] &&
            <div className="text-sm text-red-600">{errors[n.id]}</div>}
        </div>
    );
}

export default LocationAutoComplete;